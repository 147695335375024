import Vue from 'vue';
import FooterSection from '@/layouts/FooterSection';
import HeaderSection from '@/layouts/HeaderSection';
import LoadingSection from '@/layouts/LoadingSection.vue';
import FBPreviewVoucher from '@/layouts/FBPreviewVoucher.vue';
import FBVoucher from '@/layouts/FBVoucher.vue';
import FootballTeamCard from '@/layouts/FootballTeamCard.vue';

Vue.component('FootballTeamCard', FootballTeamCard);
Vue.component('FBVoucher', FBVoucher);
Vue.component('FBPreviewVoucher', FBPreviewVoucher);
Vue.component('HeaderSection', HeaderSection);
Vue.component('FooterSection', FooterSection);
Vue.component('LoadingSection', LoadingSection);