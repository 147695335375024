<template>
  <div>
    <LoadingSection v-if="isLoading"/>
  <!-- welcome slider Section -->
    <section class="matchboard mx-3 position-relative">
      <FooterSection />

      <HeaderSection />
      <h1 class="fw-500 mb-3">Live Matches</h1>
      <ul class="nav nav-pills my-3 d-flex justify-content-between" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact"
            type="button" role="tab" aria-controls="pills-contact" aria-selected="true"><i class="bi bi-trophy"></i>
            Live</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
            type="button" role="tab" aria-controls="pills-home" aria-selected="false"><i class="bi bi-cast"></i>
            Coming</button>
        </li>

      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-contact" role="tabpanel" aria-labelledby="pills-home-tab"
          tabindex="0">
          <a @click="$router.push(`/live-detail?i=`+index)" class="match-card mb-3 position-relative"  v-for="(liveMatch, index) in liveMatches" :key="index">
            <p class="dark-text px-3 pt-3">{{liveMatch.league_name}}</p>
            <div class="card-inner">
              <div class="d-flex flex-column gap-2">
                <img width="65" class="mx-auto" :src="liveMatch.home_team_logo" alt="">
                <span class="score-btn">{{liveMatch.home_team_name}}</span>
              </div>
              <div>
                <p class="text-center mb-0 small">VS</p>
                <p class="text-blue text-center mb-0" style="font-size: 0.8rem;">{{liveMatch.match_time}}</p>
              </div>
              <div class="d-flex flex-column gap-2">
                <img width="65" class="mx-auto" :src="liveMatch.away_team_logo" alt="">
                <span class="score-btn" >{{liveMatch.away_team_name}}</span>
              </div>
            </div>
            <div class="top-badge">
              <i class="bi bi-trophy"></i>
            </div>
          </a>
        </div>
        <div class="tab-pane fade" id="pills-home" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
          <a class="match-card mb-3 position-relative"  v-for="(liveMatch, index) in pendingMatches" :key="index">
            <p class="dark-text px-3 pt-3">{{liveMatch.league_name}}</p>
            <div class="card-inner">
              <div class="d-flex flex-column gap-2">
                <img width="65" class="mx-auto" :src="liveMatch.home_team_logo" alt="">
                <span class="score-btn">{{liveMatch.home_team_name}}</span>
              </div>
              <div>
                <p class="text-center mb-0 small">VS</p>
                <p class="text-blue text-center mb-0" style="font-size: 0.8rem;">{{liveMatch.match_time}}</p>
              </div>
              <div class="d-flex flex-column gap-2">
                <img width="65" class="mx-auto" :src="liveMatch.away_team_logo" alt="">
                <span class="score-btn" >{{liveMatch.away_team_name}}</span>
              </div>
            </div>
            <div class="top-badge">
              <i class="bi bi-trophy"></i>
            </div>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LoadingSection from '@/layouts/LoadingSection.vue';

export default {
  methods: {
    async fetchMatches() {
      try {
        this.isLoading = true;
        const res = await axios.get("https://api.myanmarsoccer.xyz/api/football-live-matches");
        // add matches to liveMatches when match_status is live
        this.liveMatches = res?.data?.data?.filter(
          (match) => match.match_status?.toLowerCase() === "live"
        );
        this.pendingMatches = res?.data?.data?.filter(
          (match) => match.match_status.toLowerCase() !== "live"
        );

        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      isLive: true,
      liveMatches: [],
      pendingMatches: []
    }
  },
  mounted() {
    this.fetchMatches();
  },
};
</script>

<style scoped>
.score-btn {
  font-size: 0.8rem;
}
</style>