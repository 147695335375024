<template>
  <div>
      <LoadingSection v-if="isLoading"/>
      <!-- welcome slider Section -->
      <section class="matchboard mx-3 position-relative">
        
        <FooterSection />

        <HeaderSection />

        <h1 class="fw-500 mb-3">Matchboard</h1>
        <!-- <div class="search-box">
          <div>
            <i class="bi bi-search"></i>
            <input type="text" placeholder="Search...">
          </div>
          <i class="bi bi-mic"></i>
        </div> -->

        <div class="bet-card d-flex align-items-center justify-content-between mb-3">
            <div class="py-3 ps-3">
              <h5>Make bets Profitably</h5>
              <p class="small mb-0">Predict in multiple matches and win up to 10,000,000 MMK.</p>
            </div>
            <img width="110" src="assets/img/make-bet.png" alt="">
          </div>
          
        <ul class="nav nav-pills my-3 d-flex justify-content-between" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
              type="button" role="tab" aria-controls="pills-home" aria-selected="true">
              Maung</button>
          </li>
          <!-- <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
              type="button" role="tab" aria-controls="pills-profile" aria-selected="false"><i
                class="bi bi-circle-fill live"></i> Body</button>
          </li> -->
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact"
              type="button" role="tab" aria-controls="pills-contact" aria-selected="false">
              Body</button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab"
            tabindex="0">
            <!-- <div class="bet-card d-flex align-items-center justify-content-between mb-3">
              <div class="py-3 ps-3">
                <h5>Make bets Profitably</h5>
                <p class="small mb-0">Predict in multiple matches and win up to $100 000.</p>
              </div>
              <img width="110" src="assets/img/make-bet.png" alt="">
            </div> -->
            <!-- <h3>Games</h3> -->
            <!-- <div class="swiper game-slider">
              <div class="swiper-wrapper games">
                <div class="swiper-slide">
                  <a href="#" class="game mt-2">
                    <img width="30" src="assets/img/football.png" alt="">
                  </a>
                </div>
                <div class="swiper-slide">
                  <a href="#" class="game mt-2">
                    <img width="30" src="assets/img/horse.png" alt="">
                  </a>
                </div>
                <div class="swiper-slide">
                  <a href="#" class="game mt-2">
                    <img width="30" src="assets/img/tennis.png" alt="">
                  </a>
                </div>
                <div class="swiper-slide">
                  <a href="#" class="game mt-2">
                    <img width="30" src="assets/img/vollyball.png" alt="">
                  </a>
                </div>
                <div class="swiper-slide">
                  <a href="#" class="game mt-2">
                    <img width="30" src="assets/img/boxing.png" alt="">
                  </a>
                </div>
                <div class="swiper-slide">
                  <a href="#" class="game mt-2">
                    <img width="30" src="assets/img/handball.png" alt="">
                  </a>
                </div>
                <div class="swiper-slide">
                  <a href="#" class="game mt-2">
                    <img width="30" src="assets/img/tennis.png" alt="">
                  </a>
                </div>
                <div class="swiper-slide">
                  <a href="#" class="game mt-2">
                    <img width="30" src="assets/img/vollyball.png" alt="">
                  </a>
                </div>
                <div class="swiper-slide">
                  <a href="#" class="game mt-2">
                    <img width="30" src="assets/img/boxing.png" alt="">
                  </a>
                </div>
                <div class="swiper-slide">
                  <a href="#" class="game mt-2">
                    <img width="30" src="assets/img/handball.png" alt="">
                  </a>
                </div>
    
              </div>
            </div> -->
            <h3 class="mt-3 pb-2">Live Matches</h3>
            <div class="swiper match-slider matches pb-3">
              <div class="swiper-wrapper">
                <!-- {{ matches }} -->
                <!-- <div class="swiper-slide match-card-sm"> -->
                  <div class="mb-5 pb-2">
                    <FootballTeamCard
                      v-for="(match, index) in matches"
                      :key="index"
                      :match="match"
                      :on-my-event="myEventSource"
                    />
                </div>
                <!-- </div> -->
              </div>
            </div>
          </div>


          <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
            <a href="match-details.html" class="match-card mb-3">
              <p class="dark-text px-3 pt-3">Football,Champions League</p>
              <div class="card-inner">
                <div class="d-flex flex-column gap-2">
                  <img width="65" src="assets/img/team1.png" alt="">
                  <span class="score-btn">2.42</span>
                </div>
                <div>
                  <p class="text-center mb-0 small">VS</p>
                  <h3 class="text-blue mb-0 text-center">0:0</h3>
                  <p class="text-center mb-0">21:16</p>
                  <span class="score-btn mt-2 px-3">1.01</span>
                </div>
                <div class="d-flex flex-column gap-2">
                  <img width="65" src="assets/img/team2.png" alt="">
                  <span class="score-btn">1.79</span>
                </div>
              </div>
            </a>
            <a href="match-details.html" class="match-card mb-3">
              <p class="dark-text px-3 pt-3">Football,Champions League</p>
              <div class="card-inner">
                <div class="d-flex flex-column gap-2">
                  <img width="65" src="assets/img/team3.png" alt="">
                  <span class="score-btn">2.42</span>
                </div>
                <div>
                  <p class="text-center mb-0 small">VS</p>
                  <h3 class="text-blue mb-0 text-center">0:0</h3>
                  <p class="text-center mb-0">21:16</p>
                  <span class="score-btn mt-2 px-3">1.01</span>
                </div>
                <div class="d-flex flex-column gap-2">
                  <img width="65" src="assets/img/team4.png" alt="">
                  <span class="score-btn">1.79</span>
                </div>
              </div>
            </a>
            <a href="match-details.html" class="match-card mb-3">
              <p class="dark-text px-3 pt-3">Football,Champions League</p>
              <div class="card-inner">
                <div class="d-flex flex-column gap-2">
                  <img width="65" src="assets/img/team3.png" alt="">
                  <span class="score-btn">2.42</span>
                </div>
                <div>
                  <p class="text-center mb-0 small">VS</p>
                  <h3 class="text-blue mb-0 text-center">0:0</h3>
                  <p class="text-center mb-0">21:16</p>
                  <span class="score-btn mt-2 px-3">1.01</span>
                </div>
                <div class="d-flex flex-column gap-2">
                  <img width="65" src="assets/img/team4.png" alt="">
                  <span class="score-btn">1.79</span>
                </div>
              </div>
            </a>
          </div>
          <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
            <a href="match-details.html" class="match-card mb-3 position-relative">
              <p class="dark-text px-3 pt-3">Football,Champions League</p>
              <div class="card-inner">
                <div class="d-flex flex-column gap-2">
                  <img width="65" src="assets/img/team3.png" alt="">
                  <span class="score-btn">2.42</span>
                </div>
                <div>
                  <p class="text-center mb-0 small">VS</p>
                  <h3 class="text-blue mb-0 text-center">0:0</h3>
                  <p class="text-center mb-0">21:16</p>
                  <span class="score-btn mt-2 px-3">1.01</span>
                </div>
                <div class="d-flex flex-column gap-2">
                  <img width="65" src="assets/img/team4.png" alt="">
                  <span class="score-btn">1.79</span>
                </div>
              </div>
              <div class="top-badge">
                <i class="bi bi-trophy"></i>
              </div>
            </a>
            <a href="match-details.html" class="match-card mb-3 position-relative">
              <p class="dark-text px-3 pt-3">Football,Champions League</p>
              <div class="card-inner">
                <div class="d-flex flex-column gap-2">
                  <img width="65" src="assets/img/team4.png" alt="">
                  <span class="score-btn">2.42</span>
                </div>
                <div>
                  <p class="text-center mb-0 small">VS</p>
                  <h3 class="text-blue mb-0 text-center">0:0</h3>
                  <p class="text-center mb-0">21:16</p>
                  <span class="score-btn mt-2 px-3">1.01</span>
                </div>
                <div class="d-flex flex-column gap-2">
                  <img width="65" src="assets/img/team3.png" alt="">
                  <span class="score-btn">1.79</span>
                </div>
              </div>
              <div class="top-badge">
                <i class="bi bi-trophy"></i>
              </div>
            </a>
            <a href="match-details.html" class="match-card mb-3 position-relative">
              <p class="dark-text px-3 pt-3">Football,Champions League</p>
              <div class="card-inner">
                <div class="d-flex flex-column gap-2">
                  <img width="65" src="assets/img/team1.png" alt="">
                  <span class="score-btn">2.42</span>
                </div>
                <div>
                  <p class="text-center mb-0 small">VS</p>
                  <h3 class="text-blue mb-0 text-center">0:0</h3>
                  <p class="text-center mb-0">21:16</p>
                  <span class="score-btn mt-2 px-3">1.01</span>
                </div>
                <div class="d-flex flex-column gap-2">
                  <img width="65" src="assets/img/team2.png" alt="">
                  <span class="score-btn">1.79</span>
                </div>
              </div>
              <div class="top-badge">
                <i class="bi bi-trophy"></i>
              </div>
            </a>
          </div>
        </div>
      </section>
  </div>
</template>

<script>
import { Loading, Dialog } from 'vant';
import { mapMutations, mapGetters } from 'vuex';
import { newEventSource } from 'vue-parent-emit';
// import FootballTeamCard from './components/FootballTeamCard.vue';
// import FBPreviewVoucher from './components/FBPreviewVoucher.vue';

export default {
  components: { Loading },
  data() {
    return {
      myEventSource: newEventSource(),
      isGoalPor: false,
      matches: [],
      maungMatches: [],
      leagues: [],
      historyLeagues: [],
      isMobile: false,
      amount: null,
      football: {},
      errors: {},
      isHistory: false,
      isToday: true,
      isLoading: false,
      checkedLeagues: [],
      currentLeagues: [],
      filteredMatches: [],
    };
  },
  computed: {
    ...mapGetters(['getFBOrder']),
    drawDate() {
      return this.matches[0]?.draw_date;
    },
  },
   watch:{
    checkedLeagues(){
      this.filteredMatches = this.matches.filter(match => {
        const isExist = this.checkedLeagues.filter(league => league === match.league)
        if(isExist.length > 0) return true;
        else false;
      })
    }
  },
  methods: {
    closeFilterModal(){
      $('#league-box').modal('hide');
    },
    resetFilter(){
      this.checkedLeagues = [];
    },
    filterModalBox() {
      $('#league-box').modal('show');
    },
    isLeagueHasMatches(matches) {
      let matchesTotal = 0;

      for (let i = 0; i < matches.length; i += 1) {
        let isShow = true;
        // if (nowTime.getHours() >= 12) {
        //   if (matches[i].close_time.A === 'PM') {
        //     matchTime.setHours(
        //       parseInt(matches[i].close_time.hh, 10) + 12,
        //       parseInt(matches[i].close_time.mm, 10) - 5,
        //       0,
        //     );
        //     isShow = matchTime > nowTime;
        //   }
        // } else {
        //   if (matches[i].close_time.A === 'AM') {
        //     matchTime.setHours(
        //       parseInt(
        //         matches[i].close_time.hh === '12'
        //           ? 0
        //           : matches[i].close_time.hh,
        //         10,
        //       ),
        //       parseInt(matches[i].close_time.mm, 10) - 5,
        //       0,
        //     );
        //   } else {
        //     isShow = false;
        //   }
        //   isShow = matchTime > nowTime;
        // }
        const matchTime = new Date(this.match.start_time);
        const nowTime = new Date();
        isShow = matchTime > nowTime;
        if (isShow) matchesTotal += 1;
      }
      if (matchesTotal > 0) return true;
      return false;
    },
    sendEvent() {
      this.myEventSource.emit();
    },
    changeHistory() {
      this.isHistory = !this.isHistory;
    },
    changeToday() {
      this.isToday = !this.isToday;
    },
    ...mapMutations(['updateFBOrder', 'setWholeFBMatches', 'setBetAmount']),
    closeModal() {
      $('#mobile-voucher').modal('hide');
    },
    showModalBox() {
      $('#amount').modal('show');
    },
    removeHistory() {
      this.setWholeFBMatches([]);
      this.setBetAmount(null);
      this.sendEvent();
    },
    setToPreviewVoucher() {
      this.errors = {};
      if (!this.amount) {
        this.errors.amount = this.$store.state.language === 'en'
          ? 'Please enter amount'
          : 'ငွေပမာဏ ထည့်ပေးပါ။';
        return false;
      }
      if (this.amount < this.football.min_maung_fees) {
        this.errors.amount = this.$store.state.language === 'en'
          ? `Please enter amount more than ${this.football.min_maung_fees}`
          : `${this.football.min_maung_fees} ထက်ပိုထည့်ပေးပါ။`;
        return false;
      }
      if (this.amount > this.football.max_maung_fees) {
        this.errors.amount = this.$store.state.language === 'en'
          ? `Please enter amount less than ${this.football.max_maung_fees}`
          : `${this.football.max_maung_fees} အောက်ထည့်ပေးပါ။`;
        return false;
      }
      const matches = this.getFBOrder.fb_matches;
      this.setBetAmount(this.amount);
      for (let i = 0; i < matches.length; i += 1) {
        matches[i].bet_amount = this.amount;
      }
      this.setWholeFBMatches(matches);
      $('#amount').modal('hide');
      this.openModalVoucher();
      return true;
    },
    openModalVoucher() {
      if (this.$store.state.fb_order.fb_matches.length === 0) {
        const dataError = this.$store.state.language === 'en'
          ? 'Please select some teams!'
          : 'အသင်းတစ်ချို့ ရွေးပေးပါ။';
        this.dialog(dataError);
      } else {
        const matches = this.getFBOrder.fb_matches;
        let isBetAmount = true;
        for (let index = 0; index < this.getFBOrder.fb_matches.length; index += 1) {
          const fbMatch = this.getFBOrder.fb_matches[index];
          if (!fbMatch.bet_amount) {
            isBetAmount = false;
          }
        }
        if (!isBetAmount) {
          $('#amount').modal('show');
        } else {
          $('#mobile-voucher').modal('show');
        }
      }
    },
    dialog(text) {
      Dialog.alert({
        message: text,
        confirmButtonText:
                    this.$store.state.language === 'en'
                      ? 'Go Back'
                      : 'နောက်သို့ပြန်သွားမည်။',
      }).then(() => {
        // on close
      });
    },
    async fetchMatches() {
      try {
        const res = await axios.get('fb-matches', {
          params: {
            isFromClient: true,
          },
        });
        this.matches = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchLeagues() {
      try {
        this.isLoading = true;
        const date = new Date();
        // const res = await axios.get('/leagues-fb-matches');
        await this.fetchMatches();
        this.isLoading = false;
        const historyRes = await axios.get(
          '/leagues-fb-matches-history',
        );
        this.historyLeagues = historyRes.data.data;
        for (let index = 0; index < this.matches.length; index += 1) {
          // league filtering
          const match = this.matches[index];
          const isLeague = this.currentLeagues.filter(league => league === match.league)
          if(isLeague.length === 0){
            this.currentLeagues.push(match.league)
          }

          if (this.matches[index].kyayed_team === this.matches[index].first_team) {
            this.matches[index].kyayed_team = this.$store.state.language === 'en'
              ? this.matches[index].first_team_en
              : this.matches[index].first_team;
          } else if (this.matches[index].kyayed_team === this.matches[index].second_team) {
            this.matches[index].kyayed_team = this.$store.state.language === 'en'
              ? this.matches[index].second_team_en
              : this.matches[index].second_team;
          }

          this.matches[index].first_team = this.$store.state.language === 'en'
            ? this.matches[index].first_team_en
            : this.matches[index].first_team;

          this.matches[index].second_team = this.$store.state.language === 'en'
            ? this.matches[index].second_team_en
            : this.matches[index].second_team;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async fetchFootball() {
      try {
        const res = await axios.get('/football');
        this.football = res.data.data[0];
        this.checkIsKyayOpen();
      } catch (error) {
        console.log(error);
      }
    },
    ready() {
      const channel = this.$pusher.subscribe('football-channel');
      channel.bind('football-matches', async (data) => {
        if (data.callApi) this.fetchLeagues();
      });
    },
    checkIsKyayOpen() {
      if (this.football && !this.football.is_kyay_open) {
        const text = this.$store.state.language === 'en'
          ? 'Football Kyay not open yet.'
          : 'ဘောလုံးကြေးမဖွင့်သေးပါ။';
        Dialog.alert({
          message: text,
          confirmButtonText:
                    this.$store.state.language === 'en'
                      ? 'Go Back'
                      : 'နောက်သို့ပြန်သွားမည်။',
        }).then(() => {
          // on close
          this.$router.push('/home');
        });
      }
    },
  },
  destroyed() {
    this.setBetAmount(null);
    this.setWholeFBMatches([]);
    // this.$pusher.unsubscribe('football-channel');
  },
  unmount() {
    // this.$pusher.unsubscribe('football-channel');
    this.updateFBOrder({ type, value: null });
  },
  created() {
    // if (!localStorage.getItem('token')) {
    //   this.$router.push('/auth/login');
    // }
    this.updateFBOrder({ type: 'type', value: 'maung' });
    // this.fetchMatches();
    this.fetchLeagues();
    this.fetchFootball();
    // this.checkIsKyayOpen();

    this.ready();
    if (window.innerWidth < 768) {
      this.isMobile = true;
    }
  },
};
</script>