export const routes = [
  {
    path: '/',
    component: render('HomePage'),
  },
  {
    path: '/live',
    component: render('LiveMatches'),
  },
  {
    path: '/live-detail',
    component: render('LiveDetail'),
  },
];

function render(template) {
  return require(`@/views/${template}.vue`).default;
}
