<template>
      <section class="matchboard mx-3 position-relative">
        <LoadingSection v-if="isLoading"/>
        <FooterSection />
        <div class="d-flex justify-content-between align-items-center mt-3 mb-2">
        <a href="#" onclick="window.history.back()">
            <i class="bi bi-arrow-left"></i>
        </a>
        <p class="mb-0">Match</p>
        <div class="d-flex justify-content-end align-items-center mt-3 mb-2 gap-2">
            <a href="deposit.html">
            <img src="assets/img/plus.png" width="25" alt="">
            </a>
            <h6 class="mb-0">299,000 MMK</h6>
            <div class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight">
            <img src="assets/img/profile.png" width="35" alt="">
            </div>
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div>
                <section class="profile px-3">
                <div class="d-flex justify-content-between align-items-center py-4">
                    <a href="#" data-bs-dismiss="offcanvas">
                    <i class="bi bi-arrow-left text-white"></i>
                    </a>
                    <p class="mb-0 text-white">Profile</p>
                    <a href="edit-profile.html">
                    <i class="bi bi-pencil-square text-white"></i>
                    </a>
                </div>
                <div class="d-flex justify-content-between align-items-center pb-3">
                    <div class="d-flex gap-2">
                    <img src="assets/img/profile.png" width="55" height="55" alt="">
                    <div>
                        <h4 class="text-white mb-0">Peter Moses</h4>
                        <span class="text-white">@petermoses32</span>
                    </div>
                    </div>
                    <a href="#" class="outline-btn py-1 px-4">Follow</a>
                </div>
                <div class="follow pb-3">
                    <div>
                    <h5 class="text-white mb-1">2425</h5>
                    <span class="text-blue">Followers</span>
                    </div>
                    <div class="divider"></div>
                    <div>
                    <h5 class="text-white mb-1">24</h5>
                    <span class="text-blue">Following</span>
                    </div>
                </div>
                <div class="profile-card">
                    <div class="card-top d-flex justify-content-between">
                    <div>
                        <p class="mb-1">My Balance</p>
                        <h3>$123.00</h3>
                    </div>
                    <div class="d-flex gap-2 align-items-center">
                        <a class="gradient-btn py-2 px-3 fs-6 fw-normal" href="deposit.html">Deposit</a>
                        <a class="outline-btn-small fs-6" href="withdraw.html">Withdraw</a>
                    </div>
                    </div>
                    <div class="card-bottom">
                    <div class="d-flex flex-column align-items-center gap-2 stat-box">
                        <div class="state-circle">
                        <h6 class="mb-0">4.45</h6>
                        </div>
                        <p>Prediction</p>
                    </div>
                    <div class="d-flex flex-column align-items-center gap-2 stat-box">
                        <div class="state-circle">
                        <h6 class="mb-0">140</h6>
                        </div>
                        <p>Win</p>
                    </div>
                    <div class="d-flex flex-column align-items-center gap-2 stat-box">
                        <div class="state-circle">
                        <h6 class="mb-0">60%</h6>
                        </div>
                        <p>Win Rate</p>
                    </div>
                    <div class="d-flex flex-column align-items-center gap-2 stat-box">
                        <div class="state-circle">
                        <h6 class="mb-0">$987</h6>
                        </div>
                        <p>Profit</p>
                    </div>
                    </div>
                </div>
                <a href="mybets.html" class="profile-row mt-3">
                    <div class="d-flex align-items-center gap-2">
                    <img src="assets/img/quest.png" alt="">
                    <p class="fw-500 mb-0">My Quest</p>
                    </div>
                    <i class="bi bi-chevron-right"></i>
                </a>
                <div class="profile-row mt-3">
                    <a href="notifications.html" class="d-flex align-items-center gap-2">
                    <img src="assets/img/notification.png" alt="">
                    <p class="fw-500 mb-0">Notification</p>
                    </a>
                    <label class="switch">
                    <input type="checkbox" checked>
                    <span class="slider round"></span>
                    </label>
                </div>
                <a href="#" class="profile-row mt-3">
                    <div class="d-flex align-items-center gap-2">
                    <img src="assets/img/prediction.png" alt="">
                    <p class="fw-500 mb-0">My Prediction</p>
                    </div>
                    <i class="bi bi-chevron-right"></i>
                </a>
                <a href="#" class="profile-row mt-3">
                    <div class="d-flex align-items-center gap-2">
                    <img src="assets/img/exchange.png" alt="">
                    <p class="fw-500 mb-0">My Exchange History</p>
                    </div>
                    <i class="bi bi-chevron-right"></i>
                </a>
                <a href="messages.html" class="profile-row mt-3">
                    <div class="d-flex align-items-center gap-2">
                    <img src="assets/img/message.png" alt="">
                    <p class="fw-500 mb-0">Message</p>
                    </div>
                    <i class="bi bi-chevron-right"></i>
                </a>
                <a href="invite.html" class="profile-row mt-3">
                    <div class="d-flex align-items-center gap-2">
                    <img src="assets/img/invite-icon.png" alt="">
                    <p class="fw-500 mb-0">Invite Friends</p>
                    </div>
                    <i class="bi bi-chevron-right"></i>
                </a>
                <a href="wallet.html" class="profile-row mt-3">
                    <div class="d-flex align-items-center gap-2">
                    <img src="assets/img/wallet.png" alt="">
                    <p class="fw-500 mb-0">Wallet</p>
                    </div>
                    <i class="bi bi-chevron-right"></i>
                </a>
                <a href="#" class="profile-row mt-3">
                    <div class="d-flex align-items-center gap-2">
                    <img src="assets/img/language.png" alt="">
                    <p class="fw-500 mb-0">Language</p>
                    </div>
                    <i class="bi bi-chevron-right"></i>
                </a>
                <a href="#" class="profile-row mt-3">
                    <div class="d-flex align-items-center gap-2">
                    <img src="assets/img/feedback.png" alt="">
                    <p class="fw-500 mb-0">Feedback</p>
                    </div>
                    <i class="bi bi-chevron-right"></i>
                </a>
                <a href="terms.html" class="profile-row my-3">
                    <div class="d-flex align-items-center gap-2">
                    <img src="assets/img/terms.png" alt="">
                    <p class="fw-500 mb-0">Terms & Policy</p>
                    </div>
                    <i class="bi bi-chevron-right"></i>
                </a>
                </section>
            </div>
            </div>
        </div>
        </div>
        <div class="match-card mb-3">
        <div class="d-flex justify-content-between mb-2 px-3 pt-3">
            <p class="dark-text mb-0">{{footballLiveMatch.league_name}}</p>
            <a href="#">
            <i class="bi bi-bell dark-text"></i>
            </a>
        </div>
        <div class="card-inner card-inner-border">
            <div class="d-flex flex-column gap-2">
            <img width="65" class="mx-auto" :src="footballLiveMatch.home_team_logo" alt="">
            <span class="score-btn" >{{footballLiveMatch.home_team_name}}</span>
            </div>
            <div>
            <!-- <p class="text-center mb-0 small">VS</p> -->
            <h3 class="text-blue mb-0 text-center">VS</h3>
            <!-- <p class="text-center mb-0">21:16</p> -->
            <!-- <button class="score-btn mt-2 px-3">1.01</button> -->
            </div>
            <div class="d-flex flex-column gap-2">
            <img width="65" class="mx-auto" :src="footballLiveMatch.away_team_logo" alt="">
            <span class="score-btn" >{{footballLiveMatch.away_team_name}}</span>
            </div>
        </div>
        <div class="px-1">
            <div
                class="mx-auto p-0 d-flex main-image-wrapper mt-2 pt-1"
                v-html="footballLink"
            ></div>
        </div>
        </div>

        <div class="d-flex px-4 gap-3 mt-5 pb-4 flex-wrap justify-content-center">
            <a class="gradient-btn w-45 d-flex justify-content-center text-nowrap" v-for="(link, index) in footballLiveMatch.servers" :key="index" @click="changeLink(link.link)"> {{ link.name }} </a>
        </div>
    </section>
</template>

<script>
export default {
    data() {
      return {
        footballLiveMatch: {},
        footballLink: null,
        isLoading: false,
      };
    },
    methods: {
      changeLink(link) {
        this.footballLink = `<iframe src='https://i.fdcdn.xyz/v11/?id=KEsECpu&theme_id=vebotv&t=1&n=SD1&c=24&link=${link}' width='100%' height='250px' allowtransparency='yes' allow='autoplay' frameborder='0' marginheight='0' marginwidth='0' allowfullscreen='' scrolling='auto'></iframe>`;
      },
      processPause(progress) {
        console.log(progress);
      },
      async fetchMatches() {
        try {
            this.isLoading = true;
          const res = await axios.get('https://api.myanmarsoccer.xyz/api/football-live-matches');
          this.footballLiveMatch = res.data.data[this.$route.query.i];
          this.footballLink = `<iframe src='https://i.fdcdn.xyz/v11/?id=KEsECpu&theme_id=vebotv&t=1&n=SD1&c=24&link=${this.footballLiveMatch.servers[0]?.link}' width='100%' height='250px' allowtransparency='yes' allow='autoplay' frameborder='0' marginheight='0' marginwidth='0' allowfullscreen='' scrolling='auto'></iframe>`;
          this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
          console.log(error);
        }
      },
    },
    mounted() {
      this.fetchMatches();
    },
  };
  </script>

<style scoped>
.score-btn {
  font-size: 0.8rem;
}
</style>