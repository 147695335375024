import './registerServiceWorker';
import Vue from 'vue';
import VueRouter from 'vue-router';

import './plugins';
import './plugins/global-components';

import axios from 'axios';
import VueAxios from 'vue-axios';

import store from './plugins/store';
import App from './App.vue';

import { routes } from './router/index';

Vue.use(VueAxios, axios);
Vue.use(VueRouter);

window.axios = axios;
window.moment = require('moment');

// axios.defaults.baseURL = 'http://localhost:8000/api/';
axios.defaults.baseURL = 'https://api.myanmarsoccer.xyz/api/';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const authToken = localStorage.getItem('token');
if (authToken) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
}

const router = new VueRouter({
  mode: 'history',
  routes,
  linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { x: 0, y: 0 };
  }
});

const app = new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
});
